<template>
  <section>
    <div class="content-header">
      <h2>What <em>not</em> to do</h2>
    </div>
    <div class="content-wrapper">
      <p>“What do you want to be when you grow up?” </p>
      <p>It seems like an innocuous question, and chances are, one you were asked countless times throughout childhood and adolescence.</p>
      <p>In the New York Times article, <a href="https://www.nytimes.com/2019/04/01/smarter-living/stop-asking-kids-what-they-want-to-be-when-they-grow-up.html" target="_blank">Stop Asking Kids What They Want to Be When They Grow Up</a>, noted organizational psychologist and bestselling author Adam Grant outlines the problems associated with this common question. He also describes the harms that can come with telling youth that they should find and define themselves by their “dream” job.</p>
      <p>Grant also hosts the WorkLife podcast, and in the episode, <a href="https://podcasts.apple.com/us/podcast/the-perils-of-following-your-career-passion/id1346314086?i=1000433418434" target="_blank">The Perils of Following Your Career Passion</a>, we learn why telling people, “Do what you love” can be unhelpful advice.  </p>
      <p>These two resources provide a blueprint for what not to do when talking to students about their career plans. They also provide sound advice about how to help students think about their career development in different, not-so-traditional ways:</p>
      <ul>
        <li>Encourage students and job seekers to get a “realistic preview of what [a job is] really like, warts and all”, paying attention to the day-to-day activities</li>
        <li>Invite students “to think about what kind of person they want to be – and about all the different things they might want to do” </li>
        <li>Expand their thinking in the decision-making process by encouraging them to ask themselves three questions: Who am I? What do I have to offer? What does the market need?  </li>
      </ul>
      <p>Will you remember Adam Grant’s wise words the next time you ask a young person what they’d like to be when they grow up?</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
